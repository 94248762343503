import { combineReducers } from 'redux';

import userReducer from './reducers/user.reducer';
import siteReducer from './reducers/site.reducer';



export default combineReducers({
  user:userReducer,
  site:siteReducer,
});
