import { Modal, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { create_location } from '../../api/location';




const AddGear = ({show, setShowModal}) => {

  const [is_new, setIsNew ] = useState(false)

  const brandRef = useRef('');
  const modelRef = useRef();
  const equipmentTyperRef = useRef();
  const imageRef = useRef();
  const descRef = useRef();

  let gear_type = [
      '360 Camera',
      'Accessory',
      'Art Materials',
      'Lighting',
      'Action Camera',
      'Audio',
      'Digital Camera',
      'Camera Lenses',
      'Other',
  ];

    useEffect(() => {

    });


  const _save_idea = () => {
    console.log(brandRef.current.value);
    console.log(modelRef.current.value);
    console.log(equipmentTyperRef.current.value);
    console.log(imageRef.current.value);
    console.log(descRef.current.value);
  }

  const is_new_brand = (value) => {
    console.log(value);
    value === 'new' ? setIsNew(true) : setIsNew(false);
  }


  return(
    <Modal show={show} size="lg" onHide={() => setShowModal(false)} animation={true}>
       <Modal.Header closeButton>
         <Modal.Title>Add New Gear to Inventory</Modal.Title>
       </Modal.Header>
       <Modal.Body>
        <p>Keep a running inventory of all the gear you use in your creativity. Cameras, lighting, batteries, etc. Always know what equiment you have and have it avaible to attach to idea, packing lists, etc.</p>
         <Form>



               <Form.Group className="mb-3">
                 <Form.Label>Brand / Manufacturer</Form.Label>
                 <Form.Select ref={brandRef}  id="brand"  onChange={(e) => is_new_brand(e.target.value)}>

                   <option key="o" value="">Pick or Add A Brand</option>
                   <option key="x" value="new">Add Brand</option>
                   {
                     // shot_types.map( (shot_type, i) => {
                     //
                     //   return(
                     //     <option key={i} value={shot_type}>{shot_type}</option>
                     //   )
                     // })
                   }

                 </Form.Select>
               </Form.Group>

              {
                is_new &&
                <Form.Group className="mb-3" controlId="location_name">
                  <Form.Label>New Brand / Manufacturer</Form.Label>
                  <Form.Control type="text" ref={brandRef} placeholder="Enter Idea Name" required  />
                </Form.Group>
              }

              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Model Name</Form.Label>
                <Form.Control type="text" ref={modelRef}/>
              </Form.Group>

               <Form.Group className="mb-3" controlId="gear_id">
                 <Form.Label>Equipment Type</Form.Label>
                 <Form.Select ref={equipmentTyperRef}  id="gear_id" >
                  {
                    gear_type.map( (gt, i) => {

                      return(
                        <option key={i} value={gt}>{gt}</option>
                      )
                    })
                  }
                 </Form.Select>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Control ref={descRef} as="textarea" placeholder="Gear Description or Use" style={{ height: '150px' }} />
              </Form.Group>
           <Form.Group className="mt-3">
             <Form.Label className="mt-4">Gear Photo</Form.Label>
             <Form.Control type="file" ref={imageRef} />
           </Form.Group>


         </Form>

       </Modal.Body>
       <Modal.Footer>

         <Button variant="primary" onClick={() => _save_idea()}>
           Add Gear
         </Button>
         <Button variant="secondary" onClick={() => setShowModal(false)}>
           Cancel
         </Button>
       </Modal.Footer>
     </Modal>
  )
}

export default AddGear;
