import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb, Loading, ViewSwitcher, IdeaCard, AddIdea } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {  faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'underscore';

const IdeaList = () => {

  const [filterText, setFilterText ] = useState('')
  const [shot_type, setShotFilter ] = useState('');
  const [platform, setPlatform ] = useState('');
  const [cardView, setCardView ] = useState(true);
  const [showModal, setShowModal ] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let shot_types = [];
  let socials = [];

  const _delete_scouted_location = (location_id, location_name) => {
    //Delete location
  }

  const show_modal = () => {
    setShowModal(true)
  }

  let ideas = [
        { idea_id:1,title:'Sledding', shot_type:'Video', socials:['Tik Tok','Facebook']},
        { idea_id:2,title:'Snow Ball Fight', shot_type:'Video', socials:['Instagram','Tik Tok','Facebook']},
        { idea_id:3,title:'Family', shot_type:'Photo', socials:['Instagram']},
        { idea_id:4,title:'Ice Tree', shot_type:'Photo', socials:['Instagram','Tik Tok']},
        { idea_id:5,title:'What Inspires You', shot_type:'Artwork', socials:['Instagram','Facebook']},
        { idea_id:6,title:'Computer Desk', shot_type:'Photo', socials:['Instagram','Tik Tok','Facebook']},
        { idea_id:7,title:'Game Ark', shot_type:'Video', socials:['Instagram','Tik Tok','Facebook']},
    ]


    const columns = [
      {
        name:'Title',
        sortable:true,
        cell: row => row.title
      },
      {
        name:'Post Type',
        sortable:true,
        cell: row => row.shot_type
      },
      {
        name:'For Socials',
        sortable:true,
        cell: row  => row.socials.map((social,i) => `${social}${i+1 < row.socials.length ? ", " : ''}`)
      },
      {
        name:'Delete',
        sortable:false,
        center:true,
        cell: row => <button className="btn btn-danger" onClick={() => _delete_scouted_location([row.location_id,row.location_name])}><i className="fas fa-trash-alt"></i></button>,
        button:true,
      },
    ]

    if(ideas){
      shot_types = _.chain(ideas).map(function(item) { return item.shot_type }).uniq().value().sort();
      socials = _.chain(ideas).map(function(item) { return item.socials }).map(function(social) { return social }).uniq().value().sort();

    }

  useEffect(() => {

  },[])

  const filteredIdeas = ideas.filter( item => {
    return (item.title.toLowerCase().includes(filterText.toLowerCase()) || item.shot_type.toLowerCase().includes(filterText.toLowerCase()) ) && item.shot_type.includes(shot_type) && item.shot_type.includes(platform)
    // || item.descript.toLowerCase().includes(filterText.toLowerCase()))   && item.category.includes(cat) && item.brand.includes(brand)
  })

  return(
    <Fragment>
      <Helmet>
        <title>New Idea List | Socialr</title>
      </Helmet>
      <BreadCrumb title="Social Ideas List" descript="Have an idea for a new social photo or video? Jot down your ideas and get them stored for future revision and getting it on the planning board to schedule the creation of your social ideas." links={[{title:'Social Ideas List',url:''}]} />
      <div className="container-fluid">
        <div className="filter-container d-flex flex-column flex-lg-row justify-content-between mb-3">
            <input className="form-control mt-2 me-lg-3 w-75" aria-label="Filter By Title" type="text" onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by Title"/>
            <select className="form-select me-3 mt-2 w-25" aria-label="Filter by category"  value={shot_type} onChange={(e) => setShotFilter(e.target.value)}>
             <option value="">All Shot Types</option>
             {
               shot_types.map((st,i) => <option key={i} value={st}>{st}</option>)
             }
           </select>
            <select className="form-select me-3 mt-2 w-25" aria-label="Filter by social platform"  value={platform} onChange={(e) => setPlatform(e.target.value)}>
             <option value="">All Plaforms</option>
             {
               socials.map((st,i) => <option key={i} value={st}>{st}</option>)
             }
           </select>
            <ViewSwitcher setCardView={setCardView} />
        </div>
          {
            ideas ?
              cardView ?
                <div className="row">
                  {
                    filteredIdeas.map((idea,i) => (
                      <div className="col-12 col-md-4 col-lg-3" key={i}>
                        <IdeaCard idea={idea} />
                      </div>
                    ))
                  }

                </div>
              :
              <DataTable className="ba-table" columns={columns} data={filteredIdeas} striped={true} pagination={true} paginationPerPage={25}
                noHeader={true} onRowClicked={(idea) => navigate.push(`/gear/${idea.idea_id}/details`)}  highlightOnHover={true} pointerOnHover={true}/>
            :
            <loading />
          }


      </div>
      <Fab icon={<FontAwesomeIcon icon={faPlus} />} alwaysShowTitle={true} onClick={() => show_modal(true)}></Fab>
      <AddIdea show={showModal} setShowModal={setShowModal} />

    </Fragment>
  )
}

export default IdeaList;
