

const ViewSwitcher = ({setCardView}) => {


  return(
    <div className="view-container d-flex flex-row bg-white">
      <button className="btn" onClick={() => setCardView(true)}><i className="fas fa-square"></i></button>
      <button className="btn" onClick={() => setCardView(false)}><i className="fas fa-list"></i></button>
    </div>
  )
}

export default ViewSwitcher;
