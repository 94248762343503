

const InfoCard = ({info}) => {


  return(
    <div className="card info-card">
      <div className="card-body">
        <div className="info-container">
          <h2 className="card-info">{info.info}</h2>
          <div className="icon-container">
            <i className={info.icon}></i>
          </div>
        </div>
        <h3>{info.title}</h3>

      </div>
    </div>
  )
}

export default InfoCard;
