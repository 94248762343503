
export const LH_TEST_COMPLETED = 'LH_TEST_COMPLETED';
export const LAUNCH_TOASTR = 'LAUNCH_TOASTR';

export const set_lh_test_complete = completed => {
  return{
    type:LH_TEST_COMPLETED,
    payload:completed,
  }
}

export const launch_toastr = message => {
  return{
    type:LAUNCH_TOASTR,
    payload:message,
  }
}
