import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb, Loading, SocialRow } from '../../components';


const Account = () => {

  const account = {
    account_name: 'Socialr.io',
    email_address:'hello@socialr.io',
    web_site:'https://socialr.io',
    created_at:'2021-12-08',
  }


  return(
    <Fragment>
      <Helmet>
        <title>User Account | Socialr.io</title>
      </Helmet>
      <BreadCrumb title="User Account" descript="" links={[{title:'User Account',url:''}]} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-9">
            <h2>Account Information</h2>
            <div className="card">
              <div className="card-body account-details">
                <p>
                  <span className="label">Account Name: </span>{account.account_name}
                </p>
                <p>
                  <span className="label">Website Address: </span> {account.web_site}
                </p>
                <p>
                  <span className="label">Primary Email: </span> {account.email_address}
                </p>
                <p>
                  <span className="label">Password:</span> **********************
                </p>
                <p>
                  <span className="label"></span>
                </p>
                <p>
                  <span className="label"></span>
                </p>
                <p>
                  <span className="label"></span>
                </p>
                <p>
                  <span className="label"></span>
                </p>
                <p>
                  <span className="label">Account Started: </span> {account.created_at}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-3">
            <h2>Connected Social Media</h2>
            <div className="card">
              <div className="card-body d-flex flex-column">
                {
                  [{name:'Instagram',icon:'fa-brands fa-instagram-square',status:true},
                  {name:'Twitter',icon:'fa-brands fa-twitter-square',status:true},
                  {name:'Facebook',icon:'fa-brands fa-facebook',status:true},
                  {name:'TikTok',icon:'fa-brands fa-tiktok',status:false},
                  // {name:'',icon:'',status:''},
                  // {name:'',icon:'',status:''},
                ].map((social,i) => {
                  return <SocialRow {...social} />
                })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Account;
