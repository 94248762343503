import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb, Loading, AddLocation, LocationCards, ViewSwitcher } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {  faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'underscore';
import { Modal, Button } from 'react-bootstrap'

const ScoutedLocations = () => {

  const [filterText, setFilterText ] = useState('')
  const [showModal, setShowModal ] = useState(false);
  const [cardView, setCardView ] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const _delete_scouted_location = (location_id, location_name) => {
    //Delete location
  }

  let locations = [
    {
      img:'https://i.ytimg.com/vi/AKliSKsr7J8/maxresdefault.jpg',
      location_name:'Dodge Park',
      city:'Sterling Heights',
      country:'United States',
      state:'Michigan',
      idea_attached:3,
      gps_location:'Yes'
    },
    {
      img:'https://media.flytographer.com/uploads/2021/10/seattle-photo-spots-photographer-flytographer-13.jpeg',
      location_name:'Pike Place Market',
      city:'Seattle',
      country:'United States',
      state:'Washington',
      idea_attached:1,
      gps_location:'No'
    },
  ];

  const description = "Find the perfect locations to shoot photos and videos for your next social post can happen at any time. Whether you are on a scouting location and finding places for future shots, documented places you have shot in the past or if you stumble across the perfect location for a future shot, you can store your locations with either photos or GPS coordinates. Click on any location card to get more details and/or add creative ideas"

    const columns = [
      {
        name:'',
        sortable:false,
        width:'150px',
        cell: row => <img src={row.img} alt={row.location_name} className="img-fluid" />
      },
      {
        name:'Location Name',
        sortable:true,
        cell: row  => row.location_name
      },
      {
        name:'City',
        sortable:true,

        cell: row  => row.city
      },
      {
        name:'State',
        sortable:true,
        cell: row  => row.state
      },
      {
        name:'Country',
        sortable:true,
        cell: row  => row.country
      },
      {
        name:'GPS Recorded',
        sortable:true,
        cell: row  => row.gps_location
      },
      {
        name:'Idea(s) Attached',
        sortable:true,
        cell: row  => row.idea_attached
      },
      {
        name:'Delete',
        sortable:false,
        center:true,
        width:'100px',
        cell: row => <button className="btn btn-danger" onClick={() => _delete_scouted_location([row.location_id,row.location_name])}><i className="fas fa-trash-alt"></i></button>,
        button:true,
      },
    ]

  useEffect(() => {

  },[])



  const show_modal = () => {
    setShowModal(true)
  }

  const filteredLocations = locations.filter( location => {
    return location.location_name.toLowerCase().includes(filterText.toLowerCase()) || location.city.toLowerCase().includes(filterText.toLowerCase()) ||
          location.state.toLowerCase().includes(filterText.toLowerCase()) || location.country.toLowerCase().includes(filterText.toLowerCase())
  })

  return(
    <Fragment>
      <Helmet>
        <title>Location Scouting | Socialr</title>
      </Helmet>
      <BreadCrumb title="Location Scouting" descript={description} links={[{title:'Location Scouting',url:''}]} />
      <div className="container-fluid">
        <div className="filter-container d-flex flex-column flex-lg-row justify-content-between mb-3">
            <input className="form-control mt-2 me-lg-3" aria-label="Filter by location name, city, state, country" type="text" onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by location name, city, state, country"/>
            <ViewSwitcher setCardView={setCardView} />
            {/* <select className="form-select me-3 mt-2 w-lg-25" aria-label="Filter by breed"  value={pt_type} onChange={(e) => setPetType(e.target.value)}>
              <option value="">All Pet Types</option>
              {
                pet_types.map((pt,i) => <option key={i} value={pt}>{pt}</option>)
              }
            </select>
            <select className="form-select w-lg-25 mt-2 me-lg-3 " aria-label="Filter by gender" value={pet_gender} onChange={(e) => setPetGender(e.target.value)}>
              <option value="">All Genders</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Unknown">Unknown</option>
            </select>
            <select className="form-select mt-2 w-lg-25" aria-label="Filter by Status" value={pet_status} onChange={(e) => setPetStatus(e.target.value)}>
              <option value="">All status</option>
              {
                pet_statuses.map((pt,i) => <option key={i} value={pt}>{pt}</option>)
              }
            </select> */}
        </div>
          {/* {
            locations ?

            :
            <Loading />
          } */}

          {
            locations ?
              cardView ?
                <div className="row">
                  {
                    filteredLocations.map((location,i) => (
                      <div className="col-12 col-md-4 col-lg-3" key={i}>
                        <LocationCards location={location} />
                      </div>
                    ))
                  }

                </div>
              :
              <DataTable className="ba-table" columns={columns} data={filteredLocations} striped={true} pagination={true} paginationPerPage={25}
                noHeader={true} onRowClicked={(location) => navigate.push(`/scounting/${location.location_id}/location`)}  highlightOnHover={true} pointerOnHover={true}/>
            :
            <loading />
          }


      </div>
      <Fab icon={<FontAwesomeIcon icon={faPlus} />} alwaysShowTitle={true} onClick={() => show_modal(true)}></Fab>
      <AddLocation show={showModal} setShowModal={setShowModal} />
    </Fragment>
  )
}

export default ScoutedLocations;
