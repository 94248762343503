

const LocationCards = ({location}) => {

  const imgStyles={
    height:200,
    width:'100%',
    backgroundImage:`url(${location.img})`,
    backgroundPosition:'center',
    backgroundSize:'cover',
  }


  return(
    <div className="card location-card mb-5">
      <div className="header-img-container" style={imgStyles}>
        <div className="header-container">
          <button className="btn btn-danger"><i className="fas fa-trash-alt"></i></button>
          <div className="idea-container">
            <span className="idea-number">{location.idea_attached}</span>
            <p>Ideas Attached</p>
          </div>
        </div>

      </div>
      <div className="card-header">
        <h5 className="card-title">
          {location.location_name}<br />{location.start_date && location.start_date} {location.end_date && ` - ${location.end_date}`}<br />
          {location.city}, {location.state} {location.country}
        </h5>
      </div>
    </div>
  )
}

export default LocationCards;
