

const SocialRow = ({icon,name,status}) => {

  const _connect = () => {

  }

  return(
    <div className="social-container">
      <div className="icon-container">
        <i className={icon}></i> <span className="ms-3">{name}</span>
      </div>
      <div className="status-container">
        {status ? <button className="btn btn-warning">Disconnect</button> : <button className="btn btn-success">Connect Now</button>}
      </div>
    </div>
  )
}

export default SocialRow;
