import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



const IsLoggedIn = ({history, children}) => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  let navigate = useNavigate();
  if(!isLoggedIn){
    navigate('/');
  }else{
    return children;
  }
  return null;
}


export default IsLoggedIn;//withRouter(IsLoggedIn);
