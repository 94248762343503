import { LH_TEST_COMPLETED, LAUNCH_TOASTR } from '../actions/site.actions';

const INITIAL_STATE = {
  test_completed:false,
  toastr:[false,''],
}


const siteReducer = (state=INITIAL_STATE, action) =>{
  switch(action.type){
    case LH_TEST_COMPLETED:
      return{
        ...state,
        test_completed:action.payload
      }
    case LAUNCH_TOASTR:
      return{
        ...state,
        toastr:action.payload
      }

    default:
      return state;
  }
}

export default siteReducer;
