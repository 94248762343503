import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb, Loading, ViewSwitcher, GearCard, AddGear } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {  faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'underscore';

const GearList = () => {

  const [filterText, setFilterText ] = useState('')
  const [cat, setCategoryFilter ] = useState('');
  const [brand, setBrandFilter ] = useState('');
  const [cardView, setCardView ] = useState(true);
  const [showModal, setShowModal ] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let categories = [];
  let brands = [];

  const _delete_scouted_location = (location_id, location_name) => {
    //Delete location
  }



  let gear = [
    {
      gear_id:1,
      category:'Action Camera',
      brand:'GoPro',
      model:'Hero 7',
      descript:'Action camera',
      qty:1
    },
    {
      gear_id:2,
      category:'360 Camera',
      brand:'Insta360',
      model:'One X',
      descript:'360 Camera',
      qty:1
    },
    {
      gear_id:5,
      category:'360 Camera',
      brand:'Insta360',
      model:'One',
      descript:'Older 360 camera but is pretty awesome',
      qty:1
    },
    {
      gear_id:3,
      category:'Accessory',
      brand:'Insta360',
      model:'OneX Battery',
      descript:'Battery for the Insta360 OneX',
      qty:2
    },
    {
      gear_id:4,
      category:'Lighting',
      brand:'Vijim',
      model:'VL120 RBG LED Video Light',
      descript:'Small color changing video light',
      qty:1
    },
    {
      gear_id:6,
      category:'Lighting',
      brand:'Apature',
      model:'Amaran Light',
      descript:'Small light panel',
      qty:1
    },
    {
      gear_id:7,
      category:'Action Camera',
      brand:'Insta360',
      model:'Go 2',
      descript:'Tiny wearable camera',
      qty:1
    },
    {
      gear_id:8,
      category:'Audio',
      brand:'Elgato',
      model:'Wave3',
      descript:'Microphone attached to computer',
      qty:1
    },
    {
      gear_id:9,
      category:'Digital Camera',
      brand:'Sony',
      model:'A7R3',
      descript:'Fancy Camera',
      qty:1
    },
    {
      gear_id:10,
      category:'Camera Lenses',
      brand:'Sony',
      model:'Vario-Tessar T* FE 24-70mm f/4 ZA OSS E-Mount Lens',
      descript:'Wide Lense',
      qty:1
    },
    {
      gear_id:11,
      category:'Camera Lenses',
      brand:'Sony',
      model:'E PZ 18-105mm f/4.0 G OSS Power Zoom Lens',
      descript:'Long Lense',
      qty:1
    },
  ];


    const columns = [
      {
        name:'Brand',
        sortable:true,
        cell: row => row.brand
      },
      {
        name:'Model',
        sortable:true,
        cell: row => row.model
      },
      {
        name:'Category',
        sortable:true,
        cell: row  => row.category
      },
      {
        name:'Description',
        sortable:true,
        cell: row  => row.descript
      },
      {
        name:'Qty',
        sortable:true,
        cell: row  => row.qty
      },
      {
        name:'Delete',
        sortable:false,
        center:true,
        cell: row => <button className="btn btn-danger" onClick={() => _delete_scouted_location([row.location_id,row.location_name])}><i className="fas fa-trash-alt"></i></button>,
        button:true,
      },
    ]

    if(gear){
      categories = _.chain(gear).map(function(item) { return item.category }).uniq().value().sort();
      brands = _.chain(gear).map(function(item) { return item.brand }).uniq().value().sort();
    }

  useEffect(() => {

  },[])

  const show_modal = () => {
    setShowModal(true)
  }

  const filteredGear = gear.filter( item => {
    return (item.brand.toLowerCase().includes(filterText.toLowerCase()) || item.model.toLowerCase().includes(filterText.toLowerCase()) || item.descript.toLowerCase().includes(filterText.toLowerCase()))
    && item.category.includes(cat) && item.brand.includes(brand)
  })

  return(
    <Fragment>
      <Helmet>
        <title>Gear List | Socialr</title>
      </Helmet>
      <BreadCrumb title="Gear List" descript="Take stock of all the gear you use to make your social media content" links={[{title:'Gear List',url:''}]} />
      <div className="container-fluid">
        <div className="filter-container d-flex flex-column flex-lg-row justify-content-between mb-3">
            <input className="form-control mt-2 me-lg-3 w-75" aria-label="Filter by brand or model" type="text" onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by brand or model"/>
             <select className="form-select me-3 mt-2 w-25" aria-label="Filter by category"  value={brand} onChange={(e) => setBrandFilter(e.target.value)}>
              <option value="">All Brands</option>
              {
                brands.map((brand,i) => <option key={i} value={brand}>{brand}</option>)
              }
            </select>
             <select className="form-select me-3 mt-2 w-25" aria-label="Filter by category"  value={cat} onChange={(e) => setCategoryFilter(e.target.value)}>
              <option value="">All Categories</option>
              {
                categories.map((cat,i) => <option key={i} value={cat}>{cat}</option>)
              }
            </select>
            <ViewSwitcher setCardView={setCardView} />
        </div>
          {/* {
            gear ?
            <DataTable className="ba-table" columns={columns} data={filteredGear} striped={true} pagination={true} paginationPerPage={25}
              noHeader={true} onRowClicked={(gear) => navigate.push(`/gear/${gear.gear_id}/details`)}  highlightOnHover={true} pointerOnHover={true}/>
            :
            <Loading />
          } */}
          {
            gear ?
              cardView ?
                <div className="row">
                  {
                    filteredGear.map((gear,i) => (
                      <div className="col-12 col-md-4 col-lg-3" key={i}>
                        <GearCard gear={gear} />
                      </div>
                    ))
                  }

                </div>
              :
              <DataTable className="ba-table" columns={columns} data={filteredGear} striped={true} pagination={true} paginationPerPage={25}
                noHeader={true} onRowClicked={(gear) => navigate.push(`/gear/${gear.gear_id}/details`)}  highlightOnHover={true} pointerOnHover={true}/>
            :
            <loading />
          }


      </div>
      <Fab icon={<FontAwesomeIcon icon={faPlus} />} alwaysShowTitle={true} onClick={() => show_modal(true)}></Fab>
      <AddGear show={showModal} setShowModal={setShowModal} />

    </Fragment>
  )
}

export default GearList;
