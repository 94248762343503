import { useState, useRef, useEffect } from 'react';
import mapboxgl, {Marker} from 'mapbox-gl'

const Map = ({long,lat, zoom, points}) => {

  mapboxgl.accessToken = 'pk.eyJ1Ijoid2tvbGN6IiwiYSI6ImNreXVjMTd1ZzFseTYydmx1d3NjcmVzbWsifQ.0LgY_WWulUhqjgq1b97mWA';
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [myzoom, setZoom] = useState(zoom)

  useEffect(() => {
    // console.log(long)
    // console.log(lat)
    // console.log(zoom)
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({ container: mapContainer.current, style: 'mapbox://styles/mapbox/streets-v11', center: [long, lat], zoom: myzoom });
  },[]);

  return(
    <div ref={mapContainer} className="map-container" />
  )
}

export default Map;
