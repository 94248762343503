import { Modal, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import { countries, usStates, provinces } from '../../data/countries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { WithContext as ReactTags } from 'react-tag-input';
import { create_location } from '../../api/location';

const KeyCodes = { comma: 188, enter: 13 };
const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = [];

const AddLocation = ({show, setShowModal}) => {

  const [location,setLocation ] = useState('');
  const [city,setCity ] = useState('');
  const [address,setAddress ] = useState('');
  const [state,setStateProv ] = useState('');
  const [states,setStatesProv ] = useState(usStates);
  const [country,setCountry ] = useState('United States');
  const [long,setLong ] = useState('');
  const [lat,setLat ] = useState('');
  const [note,setNote ] = useState('');
  const [is_pinnable,setPin ] = useState(false);
  const [tags, setTags] = useState([])



  const _save_location = () => {

  }

  useEffect(() => {
    "geolocation" in navigator ? setPin(true) : setPin(false)
  });

  const drop_pin = () => {
    //Maybe use google to get location info (city, state, country)
    navigator.geolocation.getCurrentPosition(function(position) {
      // console.log(position)
      // console.log("Latitude is :", position.coords.latitude);
      // console.log("Longitude is :", position.coords.longitude);
      setLat(position.coords.latitude)
      setLong(position.coords.longitude)
    });
  }

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const _change_state_prov = (country) => {
    console.log(country)
    setCountry(country);
    if(country === 'United States'){
      console.log('I am US')
      setStatesProv(usStates)
    }else if(country === 'Canada'){
      console.log('I am canada')
      setStatesProv(provinces)
    }
  }


  return(
    <Modal show={show} onHide={() => setShowModal(false)} animation={true}>
       <Modal.Header closeButton>
         <Modal.Title>New Scouted Location</Modal.Title>
       </Modal.Header>
       <Modal.Body>

         <Form>
           <Form.Group className="mb-3" controlId="location_name">
             <Form.Label>Location Name * </Form.Label>
             <Form.Control type="text" placeholder="Enter Location Name" required value={location} onChange={(e) => setLocation(e.target.value)} />
           </Form.Group>
           <Form.Group className="mb-3" controlId="address">
             <Form.Label>Address</Form.Label>
             <Form.Control type="text" placeholder="Optional"  value={address} onChange={(e) => setAddress(e.target.value)}/>
           </Form.Group>

            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" placeholder="Optional"  value={city} onChange={(e) => setCity(e.target.value)}/>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                {
                  country === 'United States' || country === 'Canada' ?
                  <Form.Group className="mb-3">
                    <Form.Label>State/Provience/Area</Form.Label>
                    <Form.Select value={state} id="state"  value={state} onChange={(e) => setStateProv(e.target.value)}>
                      {
                        states.map( (state, i) => {

                          return(
                            <option key={i} value={state.name}>{state.name}</option>
                          )
                        })
                      }

                    </Form.Select>
                  </Form.Group>
                  :
                  <Form.Group className="mb-3" controlId="state">
                    <Form.Label>State/Provience/Area</Form.Label>
                    <Form.Control type="text" placeholder="Optional"  value={state} onChange={(e) => setStateProv(e.target.value)}/>
                  </Form.Group>

                }


              </Col>
            </Row>





           <Form.Group className="mb-3">
             <Form.Label>Country</Form.Label>
             <Form.Select value={country} id="country"  value={country} onChange={(e) => _change_state_prov(e.target.value)}>
               {
                 countries.map( (country, i) => {

                   return(
                     <option key={i} value={country.name}>{country.name}</option>
                   )
                 })
               }

             </Form.Select>
           </Form.Group>

           <Row>
             <Col xs={12} md={6}>
               <Form.Group className="mb-3" controlId="city">
                 <Form.Label>Longitude</Form.Label>
                 <Form.Control type="text" placeholder="Optional" value={long} />
               </Form.Group>
             </Col>
             <Col xs={12} md={6}>
               <Form.Group className="mb-3" controlId="city">
                 <Form.Label>Latitude</Form.Label>
                 <Form.Control type="text" placeholder="Optional" value={lat} />
               </Form.Group>
             </Col>
           </Row>

           {
             is_pinnable &&
             <Button variant="primary" onClick={() => drop_pin()}><FontAwesomeIcon icon={faMapMarkerAlt} /> Drop Pin Here</Button>
           }

           <Form.Group className="mt-3">
             <Form.Control as="textarea" placeholder="Location Notes" style={{ height: '150px' }} />
           </Form.Group>

           <Form.Group>
             <Form.Label className="mt-4">Keywords / Tags</Form.Label>
             <ReactTags tags={tags} suggestions={suggestions} delimiters={delimiters} handleDelete={handleDelete}
               handleAddition={handleAddition} inputFieldPosition="bottom" autocomplete />
           </Form.Group>

           




         </Form>

       </Modal.Body>
       <Modal.Footer>

         <Button variant="primary" onClick={() => _save_location()}>
           Save Location
         </Button>
         <Button variant="secondary" onClick={() => setShowModal(false)}>
           Cancel
         </Button>
       </Modal.Footer>
     </Modal>
  )
}

export default AddLocation;
