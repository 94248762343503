import { SET_ALL_USERS,SET_USER,SET_CURRENT_USER,LOGOUT_USER } from '../actions/user.actions';

const INITIAL_STATE = {
  users:[],
  user:{},
  isLoggedIn:true,
  currentUser:{user_id:1,account_id:1},
}

const userReducer = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL_USERS:
      return {
        ...state,
        users:action.payload
      }
    case SET_USER:
      return{
        ...state,
        user:action.payload,
      }
    case SET_CURRENT_USER:
    console.log('I am setting user');
      return{
        ...state,
        currentUser:action.payload,
        isLoggedIn:true,
      }
    case LOGOUT_USER:
      return{
        ...state,
        currentUser:null,
        isLoggedIn:false,
      }
    default:
      return state;
  }
}

export default userReducer;
