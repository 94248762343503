import { useState, Fragment, useEffect } from 'react';
import { AppHeader,AppNav } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, MenuItem, Menu, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faLightbulb, faUsers, faUserCircle, faSignOutAlt, faCalendarAlt, faEnvelope , faChartPie, faCamera, faHandsHelping, faHandHoldingHeart, faShareSquare} from '@fortawesome/free-solid-svg-icons';
import { logoutUser } from '../../redux/actions/user.actions';
import _ from 'underscore';

const Main = ({ children, history }) => {

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  const user = useSelector(state => state.user.currentUser);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  const logoutThisUser = (logoutUser) => {
    dispatch(logoutUser());
    navigate('/');
  }

  useEffect(() => {
    // if(!user || _.isEmpty(user) ||  !isLoggedIn){
    //   logoutThisUser(logoutUser);
    // }
  });


    return(
      <Fragment>
        <AppHeader />
          <ProSidebar collapsed={collapsed}>
            <SidebarHeader className='control'>
              {/**
                *  You can add a header for the sidebar ex: logo
              */}

              <div className="text-center">
                {
                  collapsed &&
                  <i className="text-center hide-show fas fa-bars" onClick={() => setCollapsed(false)}></i>
                }
                {
                  !collapsed &&
                  <i className="text-center hide-show fas fa-arrow-circle-left" onClick={() => setCollapsed(true)}></i>
                }
              </div>



            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem icon={<FontAwesomeIcon icon={faTachometerAlt} />}> Dashboard <Link title="Dashboard" to="/"/></MenuItem>
                {/* <SubMenu title="Analytics" icon={<FontAwesomeIcon icon={faChartBar} />}>
                  <MenuItem><i className="fas fa-chart-line me-3"></i>Price Spider<Link title="Price Spider" to="/reports/pricespider"/></MenuItem>

                </SubMenu> */}

                {/* <SubMenu title="Socal Media Planners" icon={<FontAwesomeIcon icon={faShareSquare} />}>
                  <MenuItem><i className="fa-brands fa-instagram-square me-3"></i>Instagram <Link title="Document or Plan Future Social Media Posts" to="/ideas"/></MenuItem>
                  <MenuItem><i className="fa-brands fa-tiktok me-3"></i>TikTok <Link title="Document or Plan Future Social Media Posts" to="/scouting"/></MenuItem>
                  <MenuItem><i className="fa-brands fa-youtube me-3"></i>YouTube <Link title="Document or Plan Future Social Media Posts" to="/scouting"/></MenuItem>
                  <MenuItem><i className="fa-brands fa-facebook-square me-3"></i>Facebook <Link title="Document or Plan Future Social Media Posts" to="/scouting"/></MenuItem>
                  <MenuItem><i className="fa-brands fa-twitter me-3"></i>Twitter <Link title="Document or Plan Future Social Media Posts" to="/scouting"/></MenuItem>
                </SubMenu> */}

                <SubMenu title="Ideas & Inspirations" icon={<FontAwesomeIcon icon={faLightbulb} />}>
                  <MenuItem><i className="fas fa-lightbulb me-3"></i>Brainstorming <Link title="Document or Plan Future Social Media Post Ideas" to="/ideas"/></MenuItem>
                  <MenuItem><i className="fas fa-map-marker-alt me-3"></i>Location Scouting <Link title="Document or Plan Future Social Media Posts" to="/scouting"/></MenuItem>
                </SubMenu>

                <SubMenu title="Planning" icon={<FontAwesomeIcon icon={faCalendarAlt} />}>
                  <MenuItem><i className="fas fa-calendar-alt me-3"></i>Social Calendar <Link title="Plan out your socal media calendar" to="/calendar"/></MenuItem>
                  <MenuItem><i className="fas fa-plane-departure me-3"></i>Trips & Itinerary <Link title="Create and manage trip itineraries" to="/trips/"/></MenuItem>
                  <MenuItem><i className="fas fa-puzzle-piece me-3"></i>Social Planning Board <Link title="Manage upcoming and in progress social media ideas and posts" to="/planning/"/></MenuItem>
                </SubMenu>

                 <SubMenu title="Inventory" icon={<FontAwesomeIcon icon={faCamera} />}>
                  <MenuItem><i className="fas fa-tachometer-alt me-3"></i>Gear Inventory <Link title="Manage all your social media creating gear" to="/gear"/></MenuItem>
                </SubMenu>

                 <SubMenu title="Collaborators" icon={<FontAwesomeIcon icon={faHandsHelping} />}>
                  <MenuItem><i className="fas fa-tachometer-alt me-3"></i>Collaborators <Link title="Manage all your social media creating gear" to="/gear"/></MenuItem>
                </SubMenu>

                 <SubMenu title="Patreon Community" icon={<FontAwesomeIcon icon={faHandHoldingHeart} />}>
                  <MenuItem><i className="fab fa-patreon me-3"></i>Patreon Donors <Link title="Manage all your social media creating gear" to="/gear"/></MenuItem>
                  <MenuItem><i className="fab fa-patreon me-3"></i>Donations <Link title="Manage all your social media creating gear" to="/gear"/></MenuItem>
                </SubMenu>

                {
                  (user.role==='admin' || user.role==='group') &&
                  <SubMenu title="Administration" icon={<FontAwesomeIcon icon={faUsers} />}>
                    <MenuItem>User Management <Link to="/mgr/users" /></MenuItem>
                  </SubMenu>
                }


              </Menu>
            </SidebarContent>
            <SidebarFooter>
                <Menu iconShape="square">
                  <MenuItem icon={<FontAwesomeIcon icon={faUserCircle} />}><Link title="Manage your account" to="/account" /></MenuItem>
                  <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={() => dispatch(logoutUser())}></MenuItem>
                </Menu>
            </SidebarFooter>
          </ProSidebar>



        <div className="app-main">


          <div className="container-fluid px-0">
            <main className="main" style={{backgroundColor:'#f4f8ff'}}>
              { children }
            </main>
          </div>
        </div>


      </Fragment>
    );

}

export default Main; //withRouter(Main);
