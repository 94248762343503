import React, { useEffect } from "react";

const ScrollToTop = ({location},prevProps) => {


	useEffect(() => {
		if (location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	},[prevProps, location])

		return <React.Fragment />
}

export default ScrollToTop;
