import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main, HMain, VisionBoard, Opps404, ScoutedLocations, TripList, GearList, Dashboard, IdeaList,Account } from './pages';
import { IsLoggedIn,ScrollToTop } from './components';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function App() {

  return (
    <BrowserRouter history={history}>
      <ScrollToTop/>
        <Routes>
          <Route index path="/login" element={ <VisionBoard />} />
          <Route path="/planning" element={<Main><VisionBoard /></Main>} />
          <Route path="/" element={<Main><Dashboard /></Main>} />
          <Route path="/scouting" element={<Main><ScoutedLocations /></Main>} />
          <Route path="/ideas" element={<Main><IdeaList /></Main>} />
          <Route path="/trips" element={<Main><TripList /></Main>} />
          <Route path="/gear" element={<Main><GearList /></Main>} />
          <Route path="/account" element={<Main><Account /></Main>} />
        </Routes>
        {/* <Opps404 /> */}
    </BrowserRouter>
  );
}

export default App;
