import {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { AppNav } from '../'
const AppHeader = () => {


  return(
    <Fragment>
      <header>
        <AppNav />

      </header>
    </Fragment>

  )
}

export default AppHeader;
