

const IdeaCard = ({idea}) => {

  const imgStyles={
    height:200,
    width:'100%',
    backgroundImage:`url(${idea.img})`,
    backgroundPosition:'center',
    backgroundSize:'cover',
  }

  return(
    <div className="card gear-card mb-5">
      <div className="header-img-container p-3" style={imgStyles}>
        <div className="header-container d-flex flex-row justify-content-between">
          <button className="btn btn-danger"><i className="fas fa-trash-alt"></i></button>
          <div className="cat-container">
            {idea.shot_type}
          </div>
        </div>

      </div>
      <div className="card-header">
        <h3 className="card-title">
          {idea.title}
        </h3>
        <h5 className="card-title">
          {idea.socials.map((social,i) => `${social}${i+1 < idea.socials.length ? ", " : ''}`)}
        </h5>
      </div>
    </div>
  )
}

export default IdeaCard;
