export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_USER = 'SET_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

//Sets current user after login
export const setCurrentUser = user => ({
  type:SET_CURRENT_USER,
  payload:user
})

//Sets individual user account info
export const setUserInfo = user => ({
  type:SET_USER,
  payload:user,
});

export const setAllUsers = users => ({
  type: SET_ALL_USERS,
  payload:users,
})

//Logout current user
export const logoutUser = () => ({
  type:LOGOUT_USER
})
