import {useState } from 'react';

const ToDo = ({items}) => {

 const [tdi, setTdi ] = useState(items);

  const _change_task_status = (tdi,e) => {
    const { value } = e.target

    let updated_list = tdi.map( item => {
      var temp = Object.assign({},item);
      if(temp.id === parseInt(value)){
        if(temp.completed === 1){
          temp.completed = 0
          _set_todo_status(value,0)
        }else{
          temp.completed = 1
          _set_todo_status(value,1)
        }
      }
      return temp;
    })

    setTdi(updated_list);
  }

  const _set_todo_status = (id,completed) => {
    //send to database to update status
    console.log(`I should update ${id} to the status of ${completed}` )
  }

  return(
    <div className="todo-list">
      <h2>To Do List</h2>
      <hr className="mb-4" />
      {tdi.map((item,i) => {
        return(
          <div className="list-row" key={i}>
            <div className="list-item">

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value={item.id} id={i} onChange={(e) => _change_task_status(tdi,e)} />
                  <label className={`form-check-label item-title ${item.completed===1 ? "stricken" : ""}`} htmlFor={i}>
                    {item.title}
                  </label>
                </div>

              <div className="item-duedate">{item.due_date}</div>
            </div>
          </div>
        )
      })}
    </div>

  )


}

export default ToDo;
