import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BreadCrumb } from '../../components';
import { v4 as uuid } from 'uuid';

const VisionBoard = () => {

  const ideaList = [
      { idea_id:1,title:'Sledding', shot_type:'Video', socials:['Instagram','Tik Tok','Facebook']},
      { idea_id:2,title:'Snow Ball Fight', shot_type:'Video', socials:['Instagram','Tik Tok','Facebook']},
      { idea_id:3,title:'Family', shot_type:'Photo', socials:['Instagram','Tik Tok','Facebook']},
      { idea_id:4,title:'Ice Tree', shot_type:'Photo', socials:['Instagram','Tik Tok','Facebook']},
      { idea_id:5,title:'What Inspires You', shot_type:'Artwork', socials:['Instagram','Tik Tok','Facebook']},
      { idea_id:6,title:'Computer Desk', shot_type:'Photo', socials:['Instagram','Tik Tok','Facebook']},
      { idea_id:7,title:'Game Ark', shot_type:'Video', socials:['Instagram','Tik Tok','Facebook']},
  ]

  const columnsList =
    {
      [uuid()]: {
        name:'Backlog',
        items:ideaList
      },
      [uuid()]: {
        name:'On Deck',
        items:[]
      },
      [uuid()]: {
        name:'In Process',
        items:[]
      },
      [uuid()]: {
        name:'Needs Editing',
        items:[]
      },
      [uuid()]: {
        name:'Ready to Post',
        items:[]
      },
      [uuid()]: {
        name:'Posted',
        items:[]
      },
    }


  const [columns,setColumns] = useState(columnsList)

  // const [ ideas, updateIdeas ] = useState(ideaList)

  useEffect(() => {
    //Pull ideas in

  },[]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    //If moving from one column to another
    if(source.droppableId !== destination.droppableId){
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index,1);
      destItems.splice(destination.index,0,removed);
       setColumns({
         ...columns,
         [source.droppableId]:{
           ...sourceColumn,
           items:sourceItems
         },
         [destination.droppableId]:{
           ...destColumn,
           items: destItems
         }
       })
    }else{
      //If reordering in the same column
      const column = columns[source.droppableId];
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index,1);
      copiedItems.splice(destination.index,0,removed)
      setColumns({
        ...columns,
        [source.droppableId]:{
          ...column,
          items:copiedItems
        }
      })
    }

    console.log(result)

  }

  return(
    <Fragment>
      <Helmet>
        <title>Social Planning Board | Socialr.io</title>
      </Helmet>
      <BreadCrumb title="Social Planning Board" descript="Organize and manager your next social media ideas into list of things to do with a Kanban board" links={[{title:'Social Planning Board',url:''}]} />
      <div className="container-fluid">

        <div className="vision-board h-100">
          <div className="row">
            <DragDropContext onDragEnd={result => onDragEnd(result,columns,setColumns)}>
              {
                Object.entries(columns).map(([id,column]) => {
                  return(
                    <div className="col-12 col-md-4 col-lg-2 mb-5">

                      <Droppable droppableId={id} key={id} >
                        {
                          (provided, snapshot) => {
                            return(
                              <div className="card p-3" {...provided.droppableProps} ref={provided.innerRef} style={{background: snapshot.isDraggingOver ? 'lightblue' : 'rgba(255, 255, 255, 1)', minHeight:500}}>
                                <h2 className="column-title mb-4">{column.name}</h2>
                                  {
                                    column.items.map((item,i) => {
                                      return(
                                        <Draggable key={item.idea_id} draggableId={item.idea_id.toString()} index={i}>
                                          {
                                            (provided) => {
                                              return(
                                                <div className="card planning-card" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                  style={{userSelect:'none',padding:16, margin:'0 0 8px 0',minHeight:50,
                                                          backgroundColor: snapshot.isDragging ? '#5b73e8' : '#5b73e8',
                                                          color:'#FFF', ...provided.draggableProps.style}}>
                                                          <h5 className="card-title">{item.title}</h5>
                                                          <p>{item.shot_type}</p>
                                                          {
                                                            item.socials.map((social,i) => `${social} ${(i+1) < item.socials.length ? ', ' : ''}`)
                                                          }
                                                </div>
                                              )
                                            }
                                          }
                                        </Draggable>
                                      )
                                    })
                                  }
                                  {provided.placeholder}
                              </div>
                            )
                          }

                        }
                      </Droppable>
                    </div>

                  )
                  })}
            </DragDropContext>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default VisionBoard;
