import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BreadCrumb, ToDo, InfoCard, ReactMap, Map } from '../../components';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const Dashboard  = () => {

  const navigate = useNavigate();
  const localizer = momentLocalizer(moment)
  const [long, setLong] = useState('-98.35');
  const [lat, setLat] = useState('39.5');
  const [zoom, setZoom] = useState(3);

  //remove
  const todo_items = [
    {id:1, title:'Finish Dashboard',due_date:'', completed:0},
    {id:2, title:'Figure out Patreon API',due_date:'', completed:0},
    {id:3, title:'Update Vision Board to move items in database',due_date:'', completed:0},
    {id:4, title:'Find Logo',due_date:'', completed:0},
    {id:5, title:'Test Long/Lat Pin Drop from phone',due_date:'', completed:0},
    {id:6, title:'Add in Map',due_date:'', completed:0},
    {id:7, title:'Test map walk to location',due_date:'', completed:0},
    {id:8, title:'Scrolling text for scripts',due_date:'', completed:0},
  ]

  const infos = [
    {info:'1', title:"Items Ready To Post", icon:"fas fa-file-upload"},
    {info:'4', title:"Items Needing Editing", icon:"fas fa-exclamation-triangle"},
    // {info:'10', title:"Posts Made This Week", icon:'far fa-smile-beam'},
    {info:'0', title:"Posts Made This Week", icon:'fas fa-meh'},
    {info:'21', title:"Ideas Available", icon:'far fa-lightbulb'},

  ];

  const events = [
    {title:'My Birthday', start:'2022-01-30', end:'2022-01-30'},
    {title:'Show and Tell - 7:30pm', start:'2022-02-17', end:'2022-02-17'},
    {title:'Meeting - 2:00pm', start:'2022-02-18', end:'2022-02-18'},
    {title:'Photo Shoot', start:'2022-02-10', end:'2022-02-11 23:00:00'},
    {title:'Sample Trip to Where ever', start:'2022-02-21', end:'2022-02-24 23:00:00'},
  ]

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        "properties": {
          "title": "You Are Here",
          "description": "This is where you live"
        },
        geometry: {
          type: 'Point',
          coordinates: [-83.001344, 42.582016]
        }
      }
    ]
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLat(position.coords.latitude)
        setLong(position.coords.longitude)
        // console.log("Latitude is :", position.coords.latitude);
        // console.log("Longitude is :", position.coords.longitude);
        setZoom(2)
    });
    } else {
      console.log("Not Available");
      setLat('39.5')
      setLong('-98.35')
      setZoom(12)
    };
  },[])



  return(
    <Fragment>
      <Helmet>
        <title>Dashboard | Socialr.io</title>
      </Helmet>

      <BreadCrumb title="Dashboard" descript="" links={[{title:'',url:''}]}/>
      <div className="container-fluid">
        <div className="row">
          {
            infos.map((info,i) => {

              return(
                <div className="col-l2 col-md-6 col-lg-3 mb-4" key={i}>
                  <InfoCard info={info} />
                </div>
              )
            })
          }
        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-lg-9">
            <div className="card">
              <div className="card-body">
                <Calendar localizer={localizer} startAccessor="start" endAccessor="end" style={{ height: 600 }} events={events} />
              </div>
            </div>
            <div className="card mt-5 mb-5">
              <div className="card-body">
                <h2 className="mb-3">Social Ideas Around You</h2>
                <div className="map-container">
                  {/* <ReactMap geojson={geojson} /> */}
                  <Map long={long} lat={lat} zoom={zoom} points={geojson} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <ToDo items={todo_items} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )



}




export default Dashboard;
