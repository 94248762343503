import { Modal, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faLightbulb, faCamera, faHashtag, faImage} from '@fortawesome/free-solid-svg-icons';
import { WithContext as ReactTags } from 'react-tag-input';
import { create_location } from '../../api/location';

const KeyCodes = { comma: 188, enter: 13 };
const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = [];



const AddIdea = ({show, setShowModal}) => {

  const [tags, setTags] = useState([])

  const ideaRef = useRef();
  const ideaDetaildRef = useRef();
  const shotTypeRef = useRef();
  const locationRef = useRef();
  const gearRef = useRef();
  const imageRef = useRef();

  const shot_types = [
    'Article',
    'Artwork',
    'Photography',
    'Podcast',
    'Video',
    'VLog',
    'Other'
  ]

  let locations = [
    {
      img:'https://i.ytimg.com/vi/AKliSKsr7J8/maxresdefault.jpg',
      location_name:'Dodge Park',
      city:'Sterling Heights',
      country:'United States',
      state:'Michigan',
      idea_attached:3,
      gps_location:'Yes'
    },
    {
      img:'https://media.flytographer.com/uploads/2021/10/seattle-photo-spots-photographer-flytographer-13.jpeg',
      location_name:'Pike Place Market',
      city:'Seattle',
      country:'United States',
      state:'Washington',
      idea_attached:1,
      gps_location:'No'
    },
  ];

  let gear = [
    {
      gear_id:1,
      category:'Action Camera',
      brand:'GoPro',
      model:'Hero 7',
      descript:'Action camera',
      qty:1
    },
    {
      gear_id:2,
      category:'360 Camera',
      brand:'Insta360',
      model:'One X',
      descript:'360 Camera',
      qty:1
    },
    {
      gear_id:5,
      category:'360 Camera',
      brand:'Insta360',
      model:'One',
      descript:'Older 360 camera but is pretty awesome',
      qty:1
    },
    {
      gear_id:3,
      category:'Accessory',
      brand:'Insta360',
      model:'OneX Battery',
      descript:'Battery for the Insta360 OneX',
      qty:2
    },
    {
      gear_id:4,
      category:'Lighting',
      brand:'Vijim',
      model:'VL120 RBG LED Video Light',
      descript:'Small color changing video light',
      qty:1
    },
    {
      gear_id:6,
      category:'Lighting',
      brand:'Apature',
      model:'Amaran Light',
      descript:'Small light panel',
      qty:1
    },
    {
      gear_id:7,
      category:'Action Camera',
      brand:'Insta360',
      model:'Go 2',
      descript:'Tiny wearable camera',
      qty:1
    },
    {
      gear_id:8,
      category:'Audio',
      brand:'Elgato',
      model:'Wave3',
      descript:'Microphone attached to computer',
      qty:1
    },
    {
      gear_id:9,
      category:'Digital Camera',
      brand:'Sony',
      model:'A7R3',
      descript:'Fancy Camera',
      qty:1
    },
    {
      gear_id:10,
      category:'Camera Lenses',
      brand:'Sony',
      model:'Vario-Tessar T* FE 24-70mm f/4 ZA OSS E-Mount Lens',
      descript:'Wide Lense',
      qty:1
    },
    {
      gear_id:11,
      category:'Camera Lenses',
      brand:'Sony',
      model:'E PZ 18-105mm f/4.0 G OSS Power Zoom Lens',
      descript:'Long Lense',
      qty:1
    },
  ];


    useEffect(() => {

    });


  const _save_idea = () => {
    console.log(ideaRef.current.value);
    console.log(ideaDetaildRef.current.value);
    console.log(shotTypeRef.current.value);
    console.log(locationRef.current.value);
    console.log(gearRef.current.value);
    console.log(imageRef.current.value);
  }

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };



  return(
    <Modal show={show} size="xl" onHide={() => setShowModal(false)} animation={true}>
       <Modal.Header closeButton>
         <Modal.Title>Record New Idea</Modal.Title>
       </Modal.Header>
       <Modal.Body>
        <p>Inspiration can strike anywhere. Have a new idea for a video, photo, podcast, original artwork or anything else creative, record it here.</p>
         <Form>
           <Form.Group className="mb-3" controlId="location_name">
             <Form.Label>Idea Name * </Form.Label>
             <Form.Control type="text" ref={ideaRef} placeholder="Enter Idea Name" required  />
           </Form.Group>
           <Row>

             <Col xs={12} md={6} lg={4}>

               <Form.Group className="mb-3">
                 <Form.Label><FontAwesomeIcon icon={faLightbulb} /> Shot Type</Form.Label>
                 <Form.Select ref={shotTypeRef}  id="shot_type" >
                   {
                     shot_types.map( (shot_type, i) => {

                       return(
                         <option key={i} value={shot_type}>{shot_type}</option>
                       )
                     })
                   }

                 </Form.Select>
               </Form.Group>

             </Col>
             <Col xs={12} md={6} lg={4}>
               <Form.Group className="mb-3" controlId="gear_id">
                 <Form.Label><FontAwesomeIcon icon={faCamera} /> Equipment</Form.Label>
                 <Form.Select ref={gearRef}  id="gear_id" >
                  <option key="x" value="None">None</option>
                  {
                    gear.map( (item, i) => {

                      return(
                        <option key={i} value={item.gear_id}>{item.brand} {item.model}</option>
                      )
                    })
                  }
                 </Form.Select>
              </Form.Group>

             </Col>

             <Col xs={12} md={6} lg={4}>
               <Form.Group className="mb-3" controlId="location_id">
                 <Form.Label><FontAwesomeIcon icon={faMapMarkerAlt} /> Location</Form.Label>
                 <Form.Select ref={locationRef}  id="location_id" >
                  <option key="x" value="None">None</option>
                  {
                    locations.map( (location, i) => {

                      return(
                        <option key={i} value={location.location_id}>{location.location_name}</option>
                      )
                    })
                  }
                 </Form.Select>
               </Form.Group>
             </Col>
           </Row>


           <Form.Group className="mt-3">
             <Form.Control ref={ideaDetaildRef} as="textarea" placeholder="Wouldn't it be cool if I...." style={{ height: '150px' }} />
           </Form.Group>

           <Form.Group className="mt-3">
             <Form.Label className="mt-4"><FontAwesomeIcon icon={faImage} /> Select Inspiration Photo</Form.Label>
             <Form.Control type="file" ref={imageRef} />
           </Form.Group>

           <Form.Group>
             <Form.Label className="mt-4"><FontAwesomeIcon icon={faHashtag} />  Keywords / Tags</Form.Label>
             <ReactTags tags={tags} suggestions={suggestions} delimiters={delimiters} handleDelete={handleDelete}
               handleAddition={handleAddition} inputFieldPosition="bottom" autocomplete />
           </Form.Group>


         </Form>

       </Modal.Body>
       <Modal.Footer>

         <Button variant="primary" onClick={() => _save_idea()}>
           Save New Idea
         </Button>
         <Button variant="secondary" onClick={() => setShowModal(false)}>
           Cancel
         </Button>
       </Modal.Footer>
     </Modal>
  )
}

export default AddIdea;
