import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumb = ({title, descript, links }) => {






  return(
    <div className="crumb-container">
      <h1>{title}</h1>
      <p className="description">{descript}</p>
      <ul className="crumb-trail">
          <li>
            <Link to='/dashboard'>Dashboard</Link>

          </li>
          {
            links.map((link,i) => {
              if(link.url!==''){
                  return <li key={i}><Link to={link.url}>{link.title}</Link></li>
              }else{
                  return <li key={i} className="bold px14">{link.title}</li>
              }

            })
          }
      </ul>
    </div>
  )
}

export default BreadCrumb;
